import { ErrorCode } from '../../domain/Errors.domain';

class ExtendableError extends Error {
    code?: string;
    callArgs?: object | string;
    errors?: unknown[];
}

export class ApiRequestError extends ExtendableError {
    constructor(message = 'Api Request Error', args?: object | string) {
        super(message);
        this.code = ErrorCode.apiRequest;
        this.callArgs = args;
    }
}

export class NotFoundError extends ExtendableError {
    constructor(message = 'Not found') {
        super(message);
        this.code = ErrorCode.notFound;
    }
}

export class InternalUserValidationError extends ExtendableError {
    constructor(message = 'Validation error') {
        super(message);
        this.code = 'EINTERNALUSERVALIDATION';
    }
}

export class ExternalUserValidationError extends ExtendableError {
    constructor(message = 'Validation error', errors: string[] = []) {
        super(message);
        this.code = 'EEXTERNALUSERVALIDATION';
        this.errors = errors;
    }
}
