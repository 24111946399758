import { ReactElement, useRef, useState, useEffect, useCallback } from 'react';
import Link from 'next/link';
import { NotFoundPageProps } from './NotFoundPage.domain';
import useOnClickOutside from '@/shared/Header/service/UseOutsideClick';
import { NotFoundError } from '@/common/app/utils/errorsUtils';
import * as S from './NotFoundPage.styles';
import { CommonMeta } from '@/common/ui/meta/CommonMeta';
import Layout from '@/shared/Layout/ui/Layout';

const NotFoundPage = ({ destinationsList }: NotFoundPageProps): ReactElement => {
    const [isOpen, setIsOpen] = useState(false);
    const refSelectors = useRef(null);
    const refHeaderSelectors = useRef(null);

    const pageMounted = useCallback(() => {
        try {
            console.log(new NotFoundError(), { location: window.location }, ['commons:NotFound']);
        } catch (err) {
            return console.log(err);
        }
    }, []);

    useEffect(pageMounted, [pageMounted]);

    const handleClickOutside = () => {
        setIsOpen(false);
    };

    useOnClickOutside(refSelectors, handleClickOutside);

    return (
        <Layout>
            <CommonMeta title="TripShock - 404 Page Not Found" description="" imageSrc="" slug="" />
            <S.Section data-testid="not-found" isOpen={isOpen} onClick={handleClickOutside}>
                <S.TripshockCardInfo isOpen={isOpen}>
                    <Link data-test-id="brand-link" href="/" passHref>
                        <a>
                            <S.BrandLink />
                        </a>
                    </Link>
                    <S.Divider />
                    <S.IconContainer>
                        <S.UFOIcon />
                    </S.IconContainer>
                    <S.Content data-test-id="children">
                        <S.WrapperForText>
                            <S.Title data-test-id="title">
                                We couldn’t find the adventure you are looking for... Perhaps aliens
                                stole this page...
                            </S.Title>
                            <S.Description data-test-id="description">
                                Don’t worry you can choose your destination below and we will get
                                you right back on track.
                            </S.Description>
                        </S.WrapperForText>
                        <S.SearchSelector isOpen={isOpen}>
                            <S.SearchSelectorHeader
                                ref={refHeaderSelectors}
                                onClick={() => {
                                    setTimeout(() => {
                                        setIsOpen(!isOpen);
                                    }, 100);
                                }}
                            >
                                Select your destination
                                <S.Indicators>
                                    <S.Indicator isActive={isOpen} />
                                </S.Indicators>
                            </S.SearchSelectorHeader>
                            <S.SearchSelectorItems>
                                <S.SearchSelectorScroll>
                                    <S.ScrollContainer>
                                        {destinationsList.map(({ slug, name, state, url }) => {
                                            return (
                                                <Link href={url.toLowerCase()} key={slug} passHref>
                                                    <S.SearchSelectorItem>
                                                        <S.SelectorItemName>
                                                            {name}
                                                        </S.SelectorItemName>
                                                        <S.SelectorItemCode>
                                                            {state.code}
                                                        </S.SelectorItemCode>
                                                    </S.SearchSelectorItem>
                                                </Link>
                                            );
                                        })}
                                    </S.ScrollContainer>
                                </S.SearchSelectorScroll>
                            </S.SearchSelectorItems>
                        </S.SearchSelector>
                    </S.Content>
                </S.TripshockCardInfo>
            </S.Section>
        </Layout>
    );
};

export { NotFoundPage };
export default NotFoundPage;
