import styled, { DefaultTheme } from 'styled-components';
import Screen from 'src/styles/Themes/Screen';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import Text from 'src/styles/Themes/Text';
import backgroundImage from '@/shared/images/Images/404-bg.jpg';
import LogoType2 from '@/shared/images/Logo/LogoType_v2';
import { borders, radius } from '@/styles/Themes/Metrics';
import UFO from '@/shared/images/icons/Ufo';

interface ISection extends DefaultTheme {
    isOpen: boolean;
    onClick: () => void;
}

interface IOpen extends DefaultTheme {
    isOpen: boolean;
}

export const Section = styled.section<ISection>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 10px;
    width: 100vw;
    height: 80vh;

    &::before {
        content: '';
        position: absolute;
        background-image: url(${backgroundImage.src});
        filter: blur(5px);
        background-size: cover;
        width: 100%;
        height: 100%;
    }
`;

export const TripshockCardInfo = styled.div<IOpen>`
    position: relative;
    background-color: ${cssVars.bg_white_dark3};
    margin: 0 4%;
    padding-bottom: 92px;
    box-shadow: ${cssVars.shadow_normal};
    border-radius: ${radius.small};

    ${Screen.ipad} {
        width: 68%;
    }
    ${Screen.desktop} {
        width: 610px;
    }
`;

export const BrandLink = styled(LogoType2).attrs({
    'title': 'TripShock!',
    'data-test-id': 'brand-link',
})`
    width: 124px;
    height: auto;
    margin: 11px auto;
    display: block;
    cursor: pointer;

    ${Screen.ipad} {
        width: 164px;
    }
    ${Screen.desktop} {
        width: 188px;
    }
`;

export const Title = styled.h3`
    color: ${cssVars.fg_gray1_white};
    text-align: center;
    ${Text.Full.large.medium}
    ${Screen.desktop} {
        margin: 20px 0;
    }
`;

export const Description = styled.p`
    color: ${cssVars.fg_gray2_gray3};
    text-align: center;
    margin: 5px 0;
    ${Text.Full.normal.regular}
    ${Screen.desktop} {
        ${Text.Ipad.medium.regular}
    }
    ${Screen.desktop} {
        ${Text.Desktop.medium.regular}
    }
`;

export const SearchSelectorItems = styled.div`
    height: 400px;
    width: 100%;
    background-color: ${cssVars.bg_white_dark3};

    ${Screen.ipad} {
        height: 442px;
    }
`;

export const SearchSelectorScroll = styled.div`
    overflow-y: scroll;
    height: 95%;

    ${Screen.desktop} {
        height: 90%;
    }

    &::-webkit-scrollbar-track {
        background-color: ${cssVars.bg_ash_normal};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${Palette.gray.light};
        border-radius: ${radius.tiny};
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    a {
        color: ${cssVars.fg_gray2_gray3};
        overflow: hidden;
        white-space: nowrap;

        &:hover {
            background-color: ${cssVars.bg_ash_normal};
            border-radius: ${radius.small};
        }
    }
`;

export const SearchSelector = styled.div<IOpen>`
    width: 88%;
    height: ${({ isOpen }) => (isOpen ? '454px' : '50px')};
    bottom: ${({ isOpen }) => (isOpen ? '-40px' : '26px')};
    border: ${({ isOpen }) => (isOpen ? 'none' : `${borders.small} ${cssVars.fg_gray3_dark1}`)};
    position: absolute;
    transition: 0.5s;
    border-radius: ${radius.normal};
    ${Text.Mobile.large.medium};
    color: ${Palette.gray.black};
    box-sizing: border-box;
    box-shadow: ${({ isOpen }) =>
        isOpen ? `0px 3.89px 23.3587px ${cssVars.shadow_normal}` : `none`};
    cursor: pointer;
    background: ${cssVars.bg_white_dark3};
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 20;
    padding: 0 20px 10px 30px;

    ${Screen.ipad} {
        height: ${(props) => (props.isOpen ? '492px' : '50px')};
        bottom: ${(props) => (props.isOpen ? '-30px' : '26px')};
    }
    ${Screen.desktop} {
        height: ${(props) => (props.isOpen ? '477px' : '50px')};
        bottom: ${(props) => (props.isOpen ? '-20px' : '26px')};
        width: 81%;
    }
`;

export const SearchSelectorHeader = styled.div`
    ${Text.Mobile.large.medium};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    color: ${cssVars.fg_gray1_white};
    background-color: ${cssVars.bg_white_dark3};
    padding-left: 7px;
    ${Text.Ipad.medium.medium};
`;

export const WrapperForText = styled.div`
    ${Screen.ipad} {
        margin: 0 40px;
    }
`;

export const SearchSelectorItem = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: ${Text.Mobile.medium.medium};
    overflow: hidden;
    padding: 4px 15px;
    border-bottom: ${borders.small} ${cssVars.bg_ash_darker};
    &:hover {
        background-color: ${cssVars.bg_ash_normal};
        border-radius: ${radius.small};
        transform: 0.3s;
        *:first-child {
            color: ${Palette.green.normal};
        }
        *:last-child {
            color: ${Palette.green.normal};
        }
    }
`;

export const SelectorItemName = styled.p`
    color: ${cssVars.fg_gray1_white};
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SelectorItemCode = styled.span`
    color: ${Palette.gray.gray3};
    padding-top: 12px;
`;

export const Indicators = styled.div`
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-right: 10px;
`;

interface Iindicator extends DefaultTheme {
    isActive: boolean;
}

export const Indicator = styled(DropdownArrow)<Iindicator>`
    ${({ isActive }) => isActive && 'transform: rotate(-180deg);'}
    transition: 0.5s;
`;

export const Divider = styled.div`
    width: 80%;
    margin: 0 auto;
    border-bottom: ${borders.small} ${Palette.gray.gray4};
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px 20px 0;
`;

export const BGImage = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    filter: blur(5px);
`;

export const ScrollContainer = styled.div`
    width: 95%;
`;

export const IconContainer = styled.div`
    text-align: center;
    margin-top: 30px;
    ${Screen.desktop} {
        margin: 35px 0 5px;
    }
`;

export const UFOIcon = styled(UFO)`
    width: 67px;
    height: auto;
    margin: auto;
    ${Screen.ipad} {
        min-width: 100px;
        min-height: 100px;
    }
`;
