const UFO = (props: { className?: string }) => {
    return (
        <svg
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`UFO ${props.className}`}
        >
            <circle cx="33.5" cy="33.5" r="33.5" fill="var(--svg-ash-dark2)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7941 29.1697C15.0794 29.1697 14.5 29.7491 14.5 30.4637C14.5 31.1784 15.0794 31.7578 15.7941 31.7578H51.9992C52.7139 31.7578 53.2933 31.1784 53.2933 30.4637C53.2933 29.7491 52.7139 29.1697 51.9992 29.1697H15.7941ZM13.5 30.4637C13.5 29.1968 14.5271 28.1697 15.7941 28.1697H51.9992C53.2662 28.1697 54.2933 29.1968 54.2933 30.4637C54.2933 31.7307 53.2662 32.7578 51.9992 32.7578H15.7941C14.5271 32.7578 13.5 31.7307 13.5 30.4637Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.9079 22.5221C25.9903 22.4666 26.0875 22.437 26.1869 22.437H41.6793C41.7786 22.437 41.8757 22.4666 41.9581 22.522L50.4901 28.2549C50.7193 28.4089 50.7802 28.7196 50.6262 28.9488C50.4722 29.178 50.1616 29.239 49.9324 29.085L41.5269 23.437H26.3394L17.9407 29.0849C17.7115 29.2389 17.4009 29.1781 17.2468 28.949C17.0927 28.7198 17.1535 28.4091 17.3827 28.255L25.9079 22.5221Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.9342 15.5C32.9695 15.5 32.0147 15.6936 31.1262 16.0693C30.2377 16.4451 29.4337 16.9953 28.7618 17.6874C28.0898 18.3796 27.5636 19.1995 27.2143 20.0987C26.865 20.9979 26.6997 21.9581 26.7283 22.9223C26.7364 23.1983 26.5193 23.4287 26.2433 23.4369C25.9672 23.4451 25.7369 23.2279 25.7287 22.9519C25.6962 21.8539 25.8844 20.7606 26.2822 19.7366C26.6799 18.7127 27.2791 17.779 28.0443 16.9909C28.8094 16.2027 29.725 15.5762 30.7367 15.1483C31.7484 14.7205 32.8357 14.5 33.9342 14.5C35.0327 14.5 36.12 14.7205 37.1317 15.1483C38.1434 15.5762 39.059 16.2027 39.8241 16.9909C40.5893 17.779 41.1885 18.7127 41.5862 19.7366C41.984 20.7606 42.1722 21.8539 42.1397 22.9519C42.1315 23.2279 41.9012 23.4451 41.6251 23.4369C41.3491 23.4287 41.132 23.1983 41.1402 22.9223C41.1687 21.9581 41.0034 20.9979 40.6541 20.0987C40.3048 19.1995 39.7786 18.3796 39.1066 17.6874C38.4347 16.9953 37.6307 16.4451 36.7422 16.0693C35.8537 15.6936 34.8989 15.5 33.9342 15.5Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.5954 27.2275C25.3596 27.2275 25.1333 27.3212 24.9666 27.488C24.7998 27.6548 24.7061 27.8811 24.7061 28.1169C24.7061 28.3931 24.4822 28.6169 24.2061 28.6169C23.9299 28.6169 23.7061 28.3931 23.7061 28.1169C23.7061 27.6158 23.9051 27.1353 24.2594 26.7809C24.6138 26.4266 25.0943 26.2275 25.5954 26.2275C26.0965 26.2275 26.5771 26.4266 26.9314 26.7809C27.2858 27.1353 27.4848 27.6158 27.4848 28.1169C27.4848 28.3931 27.261 28.6169 26.9848 28.6169C26.7087 28.6169 26.4848 28.3931 26.4848 28.1169C26.4848 27.8811 26.3911 27.6548 26.2243 27.488C26.0575 27.3212 25.8313 27.2275 25.5954 27.2275Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.9377 27.2275C33.8209 27.2275 33.7053 27.2505 33.5974 27.2952C33.4895 27.3399 33.3914 27.4054 33.3088 27.488C33.2262 27.5706 33.1607 27.6687 33.116 27.7766C33.0713 27.8845 33.0483 28.0001 33.0483 28.1169C33.0483 28.3931 32.8245 28.6169 32.5483 28.6169C32.2722 28.6169 32.0483 28.3931 32.0483 28.1169C32.0483 27.8688 32.0972 27.6231 32.1922 27.3939C32.2871 27.1647 32.4263 26.9564 32.6017 26.7809C32.7772 26.6055 32.9855 26.4663 33.2147 26.3714C33.4439 26.2764 33.6896 26.2275 33.9377 26.2275C34.1859 26.2275 34.4315 26.2764 34.6608 26.3714C34.89 26.4663 35.0983 26.6055 35.2737 26.7809C35.4492 26.9564 35.5884 27.1647 35.6833 27.3939C35.7783 27.6231 35.8271 27.8688 35.8271 28.1169C35.8271 28.3931 35.6033 28.6169 35.3271 28.6169C35.051 28.6169 34.8271 28.3931 34.8271 28.1169C34.8271 28.0001 34.8041 27.8845 34.7594 27.7766C34.7147 27.6687 34.6492 27.5706 34.5666 27.488C34.484 27.4054 34.386 27.3399 34.2781 27.2952C34.1702 27.2505 34.0545 27.2275 33.9377 27.2275Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.2727 27.2275C42.1559 27.2275 42.0402 27.2505 41.9323 27.2952C41.8244 27.3399 41.7264 27.4054 41.6438 27.488C41.5612 27.5706 41.4957 27.6687 41.451 27.7766C41.4063 27.8845 41.3833 28.0001 41.3833 28.1169C41.3833 28.3931 41.1594 28.6169 40.8833 28.6169C40.6072 28.6169 40.3833 28.3931 40.3833 28.1169C40.3833 27.8688 40.4322 27.6231 40.5271 27.3939C40.6221 27.1647 40.7612 26.9564 40.9367 26.7809C41.1121 26.6055 41.3204 26.4663 41.5497 26.3714C41.7789 26.2764 42.0246 26.2275 42.2727 26.2275C42.5208 26.2275 42.7665 26.2764 42.9957 26.3714C43.225 26.4663 43.4332 26.6055 43.6087 26.7809C43.7841 26.9564 43.9233 27.1647 44.0183 27.3939C44.1132 27.6231 44.1621 27.8688 44.1621 28.1169C44.1621 28.3931 43.9382 28.6169 43.6621 28.6169C43.3859 28.6169 43.1621 28.3931 43.1621 28.1169C43.1621 28.0001 43.1391 27.8845 43.0944 27.7766C43.0497 27.6687 42.9842 27.5706 42.9016 27.488C42.819 27.4054 42.721 27.3399 42.613 27.2952C42.5051 27.2505 42.3895 27.2275 42.2727 27.2275Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.3996 34.2335C27.6648 34.3104 27.8175 34.5877 27.7407 34.8529L23.4174 49.772C23.3405 50.0373 23.0632 50.19 22.798 50.1131C22.5327 50.0362 22.38 49.7589 22.4569 49.4937L26.7802 34.5746C26.8571 34.3094 27.1344 34.1567 27.3996 34.2335Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.6854 34.2175C31.9595 34.2513 32.1542 34.501 32.1203 34.775L30.967 44.1028C30.9331 44.3769 30.6835 44.5716 30.4094 44.5377C30.1353 44.5038 29.9406 44.2542 29.9745 43.9801L31.1279 34.6523C31.1617 34.3783 31.4114 34.1836 31.6854 34.2175Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.2443 34.2335C41.5096 34.1567 41.7869 34.3094 41.8637 34.5746L45.3372 46.5666C45.414 46.8318 45.2613 47.1091 44.996 47.186C44.7308 47.2628 44.4535 47.11 44.3767 46.8448L40.9032 34.8529C40.8263 34.5876 40.9791 34.3103 41.2443 34.2335Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.9624 34.2172C36.2365 34.1843 36.4855 34.3798 36.5185 34.654L38.5958 51.9405C38.6287 52.2147 38.4332 52.4636 38.159 52.4966C37.8849 52.5295 37.6359 52.334 37.6029 52.0598L35.5256 34.7733C35.4926 34.4992 35.6882 34.2502 35.9624 34.2172Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};

export default UFO;
