import destinationsListCache from 'scripts/cache/destinationsList.json';
import NotFoundPage from '../src/screens/NotFoundPage/NotFoundPage';

export async function getStaticProps() {
    return {
        props: {
            destinationsList: destinationsListCache,
        },
    };
}
export default NotFoundPage;
